import { PT } from "~/utils/products";
import { type UploadFile } from "@solid-primitives/upload";

export const reasons = [
  "Product damaged, shipping box OK",
  "Product & shipping box both damaged",
  "Wrong item sent",
  "Product no longer needed",
];

export const deserializeSku = (sku: string): string => {
  switch (true) {
    case sku.includes("GF"):
      return sku.split("GF")[0];
    case sku.includes("-"):
      return sku.split("-")[0];
    default:
      return sku;
  }
};

const level = (val: string | number): string => {
  switch (val) {
    case "I":
      return "Inside";
    case "O":
      return "Outside";
    default:
      return val.toString();
  }
};

export const dimensionString = (obj: {
  Length?: number;
  LengthFraction?: string;
  Width?: number;
  WidthFraction?: string;
  Level?: string;
}): string => {
  let result = "";
  if (obj.Length || (obj.LengthFraction !== "0" && obj.LengthFraction !== "")) {
    result += `${obj.Length ? obj.Length.toString() : ""}${
      obj.LengthFraction !== "0" ? ` ${obj.LengthFraction}` : ""
    }"`;
  }
  if (obj.Width || (obj.WidthFraction !== "0" && obj.WidthFraction !== "")) {
    result += `${obj.Width ? " x " + obj.Width.toString() : ""}${
      obj.WidthFraction !== "0" ? ` ${obj.WidthFraction}` : ""
    }"`;
  }
  if (obj.Level) {
    result += ` (${level(obj.Level)})`;
  }

  return result;
};

export const qtyToDisplay = (obj: {
  Quantity?: number;
  CalculatedQuantity?: number;
  Type: string;
}) => {
  switch (obj.Type) {
    case PT.JOIN:
    case PT.CHOP:
    case PT.RAIL:
      return { string: obj.Quantity?.toString(), num: obj.Quantity };
    case PT.LENGTH:
    case PT.PHOTOFRAME:
    case PT.GALLERYFRAME:
    case PT.CORNERSAMPLE:
      return {
        string: obj.CalculatedQuantity?.toString(),
        num: obj.CalculatedQuantity,
      };
    case PT.BOX:
      return {
        string: `${obj.Quantity} x (${obj.CalculatedQuantity}ft)`,
        num: obj.Quantity,
      };
  }
};

export const cleanUploads = (fileArr: UploadFile[]) => {
  const { files, errors: fileTypeErrors } = pruneNonImagesVideos(fileArr);
  const { files: filesUnderLimit, errors: fileSizeErrors } =
    isFileUnderLimit(files);

  return {
    files: filesUnderLimit,
    errors: [...fileTypeErrors, ...fileSizeErrors],
  };
};

const isFileUnderLimit = (fileArr: UploadFile[], limit: number = 50) => {
  const errors = [];
  const files = [];
  const limitInBytes = limit * 1048576;

  for (const file of fileArr) {
    if (file.size <= limitInBytes) {
      files.push(file);
    } else {
      errors.push(`${file.name}: File size too large (${limit}MB Max)`);
    }
  }
  return { files, errors };
};

export const pruneNonImagesVideos = (fileArr: UploadFile[]) => {
  const errors = [];
  const images = [];

  for (const file of fileArr) {
    if (isFileImageOrVideo(file)) {
      images.push(file);
    } else {
      errors.push(`${file.name}: Invalid file format`);
    }
  }

  return { files: images, errors };
};

const isFileImageOrVideo = (file: UploadFile): boolean => {
  const allowedTypes = [
    "image/jpeg",
    "image/png",
    "image/png",
    "image/webp",
    "video/mp4",
    "video/quicktime",
    "video/mov",
    "video/avi",
  ];
  return allowedTypes.includes(file.file.type);
};

/**
 *
 * @param id the ThumbnailGroupUUI returned after converting a video with uploadCare
 * @param size pixels of preview
 * @returns thumbnail image url string
 */
export const getThumbnailByGroupID = (
  id: string,
  size: number = 500
): string => {
  return `https://ucarecdn.com/${id}/nth/0/-/preview/${size}x${size}/-/crop/1:1/-/quality/smart/-/format/jpeg/`;
};

/**
 *
 * @param id uuid returned after coverting a video with uploadCare
 * @returns url string
 */
export const getVideoURL = (id: string) => {
  return `https://ucarecdn.com/${id}/`;
};

/**
 *
 * @param url cdnUrl returned after uploading an image with uploadCare
 * @param size pixels of preview/thumbnail
 * @returns url string
 */
export const getImagePreviewURL = (url: string, size: number = 500): string => {
  return `${url}-/preview/${size}x${size}/-/crop/1:1/-/quality/smart/-/format/jpeg/`;
};
