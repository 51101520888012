import { Show, createSignal, Index, createMemo, Component } from "solid-js";
import { A, createAsync, RouteDefinition, useNavigate } from "@solidjs/router";
import {
  useSiteContext,
  useSessionContext,
  useErrorContext,
} from "~/utils/contexts";
import Body from "~/components/Body";
import { TextFieldInput, SelectBox, SelectOption } from "~/components/inputs";
import Button from "~/components/Button";
import { createForm } from "@felte/solid";
import * as z from "zod";
import { validator } from "@felte/validator-zod";
import { EventType } from "@solid-primitives/analytics";
import mailSuccess from "~/assets/mailSuccess.png";
import {
  // ProductReturnRow,
  InvoicedLine,
  getImagePreviewURL,
} from "~/components/ordering/returns";
import { type UploadedImage } from "~/components/ordering/returns/types";
import { getAccountProfile } from "~/services/account";
import { getInvoices } from "~/services/invoices";
import type { Invoice } from "~/services/roma-api/invoices/types";
import { checkError } from "~/services/roma-api/errors";
import { AuthBarrier } from "~/components/utility/AuthBarrier";
import { clientOnly } from "@solidjs/start";

const ClientProductReturnRow = clientOnly(() =>
  import("~/components/ordering/returns/ProductReturnRow").then((mod) => ({
    default: mod.ProductReturnRow,
  }))
);

export const route = {
  preload: () => {
    getAccountProfile();
    getInvoices({
      from: new Date().toISOString().split("T")[0],
      days: 90,
      lines: true,
    });
  },
} satisfies RouteDefinition;

export default function Returns() {
  const { session } = useSessionContext();
  const { track } = useSiteContext();
  const { addError } = useErrorContext();
  const [submitted, setSubmitted] = createSignal();
  const today = new Date().toISOString().split("T")[0];
  const navigate = useNavigate();
  let mainRef: HTMLElement;

  const account = createAsync(
    async () => {
      try {
        return await getAccountProfile();
      } catch (error) {
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log(
            "[returns-request.tsx]: Error caught in createAsync: ",
            err
          );
        }
        addError(err, {
          severity: "critical",
          showDetails: true,
          title: "Account Error",
          message:
            "An error occurred while retrieving your account details. Please try again. If this error persists, kindly contact customer support. We apologize for the inconvenience.",
          actions: [
            {
              label: "Refresh",
              action: () => {
                window.location.reload();
              },
            },
            {
              label: "Contact Support",
              action: async () => {
                navigate("/support");
              },
            },
          ],
        });
      }
    },
    { deferStream: true }
  );
  const orders = createAsync(
    async () => {
      try {
        const data = await getInvoices({ from: today, days: 90, lines: true });

        if (data) {
          data.Results = data.Results.reverse();

          return data;
        }
      } catch (error) {
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log(
            "[returns-request.tsx]: Error caught in createAsync: ",
            err
          );
        }
        addError(err, {
          severity: "critical",
          showDetails: true,
          title: "Order History Error",
          message:
            "An error occurred while retrieving your order history. Please try again. If this error persists, kindly contact customer support. We apologize for the inconvenience.",
          actions: [
            {
              label: "Refresh",
              action: () => {
                window.location.reload();
              },
            },
            {
              label: "Contact Support",
              action: async () => {
                navigate("/support");
              },
            },
          ],
        });
      }
    },
    { deferStream: true }
  );

  const orderOptions = createMemo(() => {
    if (!orders()) return [];

    return (
      orders()?.Results.map((item: Invoice) => ({
        value: item.InvoiceID,
        label: `Invoice: ${item.InvoiceID}, ${item.Date}, Order: ${
          item.OrderID
        }${item.PONumber ? `, PO: ${item.PONumber}` : ""}`,
        invoiceDate: item.Date,
        orderID: item.OrderID,
        lines: item.Lines!.filter((item) => item.SKU !== "WEDGE"),
        invoiceID: item.InvoiceID,
        poNumber: item.PONumber,
      })) || []
    );
  });

  const hasRecentOrders = createMemo(() => {
    if (!orders() || !orderOptions()) return false;

    return orderOptions()?.length > 0;
  });

  type ReturnFormBody = {
    Name: string;
    CustomerNumber: string;
    AccountID: string;
    Email: string;
    InvoiceNumber?: string;
    InvoiceDate?: string;
    OrderNumber?: string;
    EntireOrder?: boolean;
    Items:
      | InvoicedLine[]
      | Pick<
          InvoicedLine,
          | "SKU"
          | "Type"
          | "ReturnQuantity"
          | "RequestType"
          | "Reason"
          | "Media"
          | "Selected"
          | "Message"
        >[];
    Message?: string;
  };

  const reqNonEmpty = (max?: number) => {
    return z
      .string()
      .nonempty({ message: "Required" })
      .max(max ?? 40, {
        message: `Must be less than ${max ?? "40"} characters long.`,
      });
  };

  const imageSchema = z.object({
    status: z.string().optional(),
    id: z.string().optional(),
    url: z.string().optional(),
    source: z.string(),
    name: z.string(),
    size: z.number(),
  });

  const itemSchema = z
    .object({
      RequestType: z.string().optional(),
      ReturnQuantity: z.string().optional(),
      Reason: z.string().optional(),
      Selected: z.boolean().optional(),
      Message: z.string().optional(),
      Media: z.array(imageSchema).optional(),
      Type: z.string().optional(),
    })
    .refine((data) => (data.Selected && !data.RequestType ? false : true), {
      message: "Required",
      path: ["RequestType"],
    })
    .refine((data) => (data.Selected && !data.ReturnQuantity ? false : true), {
      message: "Required",
      path: ["ReturnQuantity"],
    })
    .refine((data) => (data.Selected && !data.Reason ? false : true), {
      message: "Required",
      path: ["Reason"],
    })
    .refine(
      (data) =>
        data.Selected && data.Type !== "product" && !data.Media ? false : true,
      {
        message: "Required",
        path: ["Media"],
      }
    );

  const schema = z.object({
    Name: reqNonEmpty(150),
    CustomerNumber: reqNonEmpty(),
    AccountID: z.string(),
    Email: reqNonEmpty(150).email(),
    InvoiceNumber: reqNonEmpty(),
    InvoiceDate: reqNonEmpty(),
    OrderNumber: reqNonEmpty(),
    Items: z.array(itemSchema),
  });

  const {
    form,
    isSubmitting,
    data,
    reset: resetForm,
    setData,
    errors,
  } = createForm<ReturnFormBody>({
    extend: validator({ schema }),
    onSubmit: async (values): Promise<void> => {
      // TODO - refactor to use a fetchAPI fn
      // Strip out the un-selected lines and unnecessary line details:
      // @ts-expect-error
      values.Items = values.Items.filter((item) => item.Selected).map(
        (item) => ({
          SKU: item.SKU,
          Type: item.Type,
          RequestType: item.RequestType,
          ReturnQuantity: item.ReturnQuantity,
          Message: item.Message,
          Reason: item.Reason,
          Media: item.Media?.filter(
            (item: UploadedImage) => item.status === "success" && item.id
          )?.map((media: UploadedImage) => ({
            ID: media.id,
            URL: media.url,
            Name: media.name,
            Type: media.file.type.includes("video") ? "video" : "image",
            Thumbnail: media.file.type.includes("video")
              ? media.thumbnailURL
              : media.url && getImagePreviewURL(media.url),
          })),
        })
      );

      track(EventType.Event, {
        category: "return_request",
        action: "return_request_submitted",
        value: JSON.stringify(values),
      });

      try {
        const response = await fetch(
          `${import.meta.env.VITE_ROMA_API}/partners/returns`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${session()?.token}`,
            },
            body: JSON.stringify(values),
          }
        );

        if (response.ok) {
          setSubmitted("success");
          if (mainRef) {
            mainRef.scrollIntoView({ behavior: "smooth" });
          }
        } else {
          setSubmitted("error");
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  form;

  const selectedItems = createMemo(() => {
    if (!data()?.Items) return 0;
    let count = 0;
    for (const item of data().Items) {
      if (item.Selected) {
        count++;
      }
    }
    return count;
  });

  const formContainsErroredImage = createMemo(() => {
    if (!data()?.Items) return false;
    for (const line of data().Items) {
      if (line.Selected) {
        if (!line.Media || line.Media.length === 0) return false;
        for (const img of line.Media) {
          if (img.status === "error") return true;
        }
      }
    }
    return false;
  });

  const mediaIsUploadingOrConverting = createMemo(() => {
    if (!data()?.Items) return false;
    for (const line of data().Items) {
      if (!line.Media || line.Media.length === 0) return false;
      for (const media of line.Media) {
        if (media.status === "uploading" || media.status === "converting")
          return true;
      }
    }
    return false;
  });

  type InvoiceLabelOption = {
    invoiceID: string;
    invoiceDate: string;
    orderID: string;
    poNumber?: string;
  };

  const InvoiceLayout: Component<InvoiceLabelOption> = (props) => {
    return (
      <div class="grid xs:grid-cols-2 md:grid-cols-4 text-xs sm:text-sm">
        <p>
          <span class="font-bold">Invoice</span>: {props.invoiceID}
        </p>
        <p>
          <span class="font-bold">Date</span>: {props.invoiceDate}
        </p>
        <p>
          <span class="font-bold">Order</span>: {props.orderID}
        </p>
        <p>
          <Show when={props.poNumber && props.poNumber !== ""}>
            <span class="font-bold">PO</span>: {props.poNumber}
          </Show>
        </p>
      </div>
    );
  };

  return (
    <Body>
      <section class="py-10 flex flex-col gap-6">
        <div class="max-w-4xl w-full mx-auto md:px-4 flex flex-col gap-6">
          <h2 class="text-2xl md:text-4xl font-bold">
            Return / Replacement Request
          </h2>
          <p>
            Your happiness is our top priority. We have made returns &
            replacements easy!
          </p>
        </div>
        <AuthBarrier permissions={"RETURNSFORM"}>
          <Show
            when={account() && orders()}
            fallback={
              <>
                <div class="bg-roma-grey rounded-md p-4 min-h-[30vh] flex justify-center items-center">
                  <p>
                    There was an error retrieving your account information.
                    Please try again or contact customer support.
                  </p>
                </div>
              </>
            }
          >
            <main ref={(ref) => (mainRef = ref)} class="bg-roma-grey">
              <Show
                when={submitted() !== "success"}
                fallback={
                  <div class="max-w-2xl mx-auto flex flex-col items-center py-10 gap-2 text-center">
                    <img src={mailSuccess} alt="" class="w-full max-w-lg" />
                    <p class="text-base sm:text-xl font-bold">
                      Your request has beeen submitted
                    </p>
                    <p class="max-sm:text-sm">
                      You will receive an email confirmation shortly. One of our
                      customer care specialists will be working on your request
                      and may reach out for more details.
                    </p>
                    <p class="text-base sm:text-xl font-bold">
                      Thank you for choosing Roma!
                    </p>
                    <div class="mt-4 flex flex-col w-full max-sm:text-sm sm:flex-row sm:justify-center gap-2 px-4">
                      <A
                        href="/"
                        class="text-roma-blue bg-white hover:bg-roma-blue hover:text-white border border-roma-blue px-2 py-1 rounded-md"
                      >
                        Home
                      </A>
                      <A
                        href="/shop"
                        class="text-roma-blue bg-white hover:bg-roma-blue hover:text-white border border-roma-blue px-2 py-1 rounded-md"
                      >
                        Shop
                      </A>
                      <button
                        onClick={() => {
                          setSubmitted(undefined);
                        }}
                        class="text-roma-blue bg-white hover:bg-roma-blue hover:text-white border border-roma-blue px-2 py-1 rounded-md"
                      >
                        Submit another request
                      </button>
                    </div>
                  </div>
                }
              >
                <form
                  use:form
                  class="max-w-4xl mx-auto rounded-md p-4 md:p-6 my-3 flex flex-col gap-2"
                >
                  <Show
                    when={account()}
                    fallback={
                      <div class="border rounded-md p-4 text-center text-sm bg-faint-blue">
                        Error retrieving customer account details, please{" "}
                        <button
                          type="button"
                          class="text-roma-blue"
                          onClick={() => location.reload()}
                        >
                          refresh
                        </button>{" "}
                        this page to try again, or contact support at{" "}
                        <a href="tel:18002632322" class="text-roma-blue">
                          1-800-263-2322
                        </a>{" "}
                        to proceed with your return request.
                      </div>
                    }
                  >
                    <div class="flex flex-col md:flex-row gap-2 w-full text-roma-dark-grey">
                      <TextFieldInput
                        name="Name"
                        label="Name"
                        defaultValue={`${account()?.FirstName} ${
                          account()?.LastName
                        }`}
                        rootClass="w-full"
                        error={errors().Name}
                        validationState={errors().Name ? "invalid" : "valid"}
                        maxLength={150}
                        required
                      />
                      <TextFieldInput
                        name="CustomerNumber"
                        label="Partner Number"
                        rootClass="w-full"
                        defaultValue={account()?.CustomerNumber}
                        error={errors().CustomerNumber}
                        validationState={
                          errors().CustomerNumber ? "invalid" : "valid"
                        }
                        required
                      />
                      <input
                        type="hidden"
                        name="AccountID"
                        value={account()?.AccountID}
                      />
                    </div>
                    <TextFieldInput
                      name="Email"
                      label="Email"
                      rootClass="w-full text-roma-dark-grey"
                      defaultValue={account()?.Email}
                      error={errors().Email}
                      validationState={errors().Email ? "invalid" : "valid"}
                      maxLength={150}
                      required
                    />
                  </Show>
                  {/* TODO - Wrap in Suspense instead of .loading check */}
                  {/* <Show when={orders().loading}>
                  <div class="border flex justify-center items-center gap-x-1 ">
                    <BaseLoader width={5} height={5} />
                    <p>Retrieving recent orders...</p>
                  </div>
                </Show> */}
                  <Show when={orders() && orderOptions()}>
                    <SelectBox
                      name="Order"
                      label="Order for Submission"
                      options={orderOptions() as SelectOption[]}
                      variantType="custom"
                      itemComp={(labelData: InvoiceLabelOption) => (
                        <InvoiceLayout {...labelData} />
                      )}
                      placeholder={
                        hasRecentOrders()
                          ? "Select an order from last 90 days"
                          : "No recent orders"
                      }
                      triggerClass="bg-white"
                      contentClass="max-w-[90vw]"
                      checkClass="-ml-5"
                      listboxClass="child:border-b child:rounded-none last:child:border-0"
                      onChange={(option) => {
                        setData((formData) => ({
                          ...formData,
                          InvoiceNumber: option.value as string,
                          InvoiceDate: option.invoiceDate,
                          OrderNumber: option.orderID,
                          Items: option.lines,
                        }));
                      }}
                      disabled={!hasRecentOrders()}
                      error={errors().InvoiceNumber}
                      validationState={
                        errors().InvoiceNumber ? "invalid" : "valid"
                      }
                    />
                  </Show>
                  <Show
                    when={data().InvoiceNumber}
                    fallback={
                      <>
                        <p class="font-light text-sm pb-1">
                          Note: Only orders invoiced in the past 90 days are
                          permitted for return / replacement.
                        </p>
                        <div class="border rounded-md p-4 text-center bg-faint-blue text-sm">
                          Please select a recent order to proceed
                        </div>
                      </>
                    }
                  >
                    <hr class="my-6" />
                    <h4 class="font-bold text-xl pb-2 mb-2">Order Details</h4>
                    <Show when={selectedItems() === 0}>
                      <div class="border rounded-md p-4 -mt-4 text-center bg-faint-blue text-sm">
                        Select item(s) for submission from invoice{" "}
                        {data().InvoiceNumber}
                      </div>
                    </Show>
                    <section class="border rounded-md bg-white py-4 px-5 ">
                      <Index each={data().Items}>
                        {(item, index) => (
                          <ClientProductReturnRow
                            index={index}
                            line={item}
                            setter={setData}
                            formData={data}
                            errors={errors}
                          />
                        )}
                      </Index>
                    </section>
                    <p class="text-sm font-light text-roma-dark-grey">
                      Note: Product must be in its original state to qualify for
                      a return/replacement/credit. Warranty is voided on any
                      altered or fitted products. Certain conditions and
                      exclusions may apply. Refer to our{" "}
                      <A
                        href="/support/returns-exchanges"
                        class="text-roma-blue"
                      >
                        Terms & Conditions
                      </A>{" "}
                      for more detail.
                    </p>
                    <Show when={formContainsErroredImage()}>
                      <div class="border border-gray-300 rounded-md -mb-4 mt-2 p-4 text-sm bg-red-50">
                        Form contains invalid images / images with errors,
                        please remove invalid images to proceed with submission.
                      </div>
                    </Show>
                    <Show when={mediaIsUploadingOrConverting()}>
                      <div class="border border-gray-300 rounded-md -mb-4 mt-2 p-4 text-sm bg-orange-50 text-center">
                        Please wait for media to finish uploading before
                        submitting form.
                      </div>
                    </Show>
                    <Button
                      class="mx-auto mt-8 w-full"
                      disabled={
                        isSubmitting() ||
                        selectedItems() === 0 ||
                        mediaIsUploadingOrConverting()
                      }
                    >
                      <span>
                        <Show
                          when={selectedItems() > 0}
                          fallback={"Select item to proceed"}
                        >
                          Submit {selectedItems()}
                          {selectedItems() > 1 ? " Items " : " Item "}for Review
                        </Show>
                      </span>
                    </Button>
                  </Show>
                  <Show when={submitted() === "error"}>
                    <div class="w-full bg-red-50 border border-red-500 rounded-md flex items-center justify-center p-4 mt-4">
                      <p>
                        Something went wrong - please try again later or contact{" "}
                        <A href="/support" class="text-roma-blue">
                          customer support
                        </A>
                      </p>
                    </div>
                  </Show>
                </form>
              </Show>
            </main>
          </Show>
        </AuthBarrier>
      </section>
    </Body>
  );
}
