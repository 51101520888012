import {
  Component,
  For,
  createMemo,
  Show,
  Switch,
  Match,
  useContext,
} from "solid-js";
import { SiteContext, useSiteContext } from "~/utils/contexts";
import {
  createDropzone,
  UploadFile,
  createFileUploader,
  fileUploader,
} from "@solid-primitives/upload";
import { EventType } from "@solid-primitives/analytics";
import { Icon } from "solid-heroicons";
import { xMark, photo, check, videoCamera } from "solid-heroicons/outline";
import { BaseLoader } from "~/components/utility";
import { cleanUploads, getVideoURL, getThumbnailByGroupID } from "./helpers";
import "./uploadDropzone.css";



import { toasty } from "~/components/utility/toast";
import { UploadClient, ProgressCallback } from "@uploadcare/upload-client";

type UploadDropzoneProps = {
  onMediaAdd: (files: UploadFile[]) => void;
  onMediaRemove?: (name: string) => void;
  formData?: () => any;
  line: () => any;
  storeSetter: (...args: any) => void;
  itemIndex: number;
  error?: string | string[] | null;
  validationState?: "valid" | "invalid";
};

export const UploadDropzone: Component<UploadDropzoneProps> = (props) => {
  const { track } = useSiteContext();
  const {
    setRef: dropzoneRef,
    files: droppedFiles,
    removeFile,
  } = createDropzone({
    onDrop: async (files) => {
      addMedia(files);
    },
  });
  const { files, selectFiles } = createFileUploader({
    multiple: true,
    accept: "image/*, video/*",
  });
  fileUploader;

  // UploadCare
  const client = new UploadClient({
    publicKey: import.meta.env.VITE_UPLOADCARE_PUBLIC,
  });

  const addMedia = (fileArr: UploadFile[]) => {
    // tracking info
    track(EventType.Event, {
      category: "return_request",
      action: "media_added",
    });

    // Clean up the files (remove non-images and images exceeding limit);
    const media = props.line().Media;
    if (media?.length >= 5 || media?.length + fileArr.length > 5) {
      alert("A maximum of 5 images/videos total may be uploaded.");
      return;
    }

    const { files: cleanedFiles, errors } = cleanUploads(fileArr);
    // display errors
    if (errors.length > 0) {
      for (let i = 0; i < errors.length; i++) {
        toasty.show({
          title: "File Upload Error",
          message: errors[i],
          type: "warning",
        });
      }
    }
    // add media to store
    props.onMediaAdd(cleanedFiles);
    // iterate through files on store, if not uploaded then
    // upload media to uploadCare
    uploadMediaToServer();
  };

  const uploadMediaToServer = async () => {
    const media = () => props.line().Media;
    if (media()) {
      for (const [index, item] of media().entries()) {
        if (!item.status) {
          uploadSingleMedia(index);
        }
      }
    }
  };

  const uploadSingleMedia = async (index: number) => {
    const file = props.line().Media[index];
    const setMediaProperty = (
      property: string,
      value: string | number | null
    ) => {
      props.storeSetter(
        `Items.${props.itemIndex}.Media.${index}.${property}`,
        value
      );
    };

    const onProgress: ProgressCallback = (data) => {
      if (data.isComputable) {
        const progress = Math.trunc(data.value * 100);
        setMediaProperty("progress", progress);
      }
    };

    setMediaProperty("status", "uploading");

    try {
      const result = await client.uploadFile(file.file, { onProgress });
      setMediaProperty("id", result.uuid);
      setMediaProperty("url", result.cdnUrl);

      if (file.file.type.includes("video") && result.uuid) {
        setMediaProperty("status", "converting");

        const response = await fetch(`/api/convert-video?uuid=${result.uuid}`);
        const converted = await response.json();

        if (!converted.error && converted.result) {
          const originalID = result.uuid;
          const newID = converted.result.uuid;
          const thumbnailGroupID = converted.result.thumbnailsGroupUuid;

          setMediaProperty("id", newID);
          setMediaProperty("url", getVideoURL(newID));

          setMediaProperty(
            "thumbnailURL",
            getThumbnailByGroupID(thumbnailGroupID)
          );

          setMediaProperty("status", "success");
        } else {
          throw new Error();
        }
      } else if (file.file.type.includes("image") && result.uuid) {
        setMediaProperty("status", "success");
      }
    } catch (error) {
      setMediaProperty("status", "error");
      toasty.show({
        title: "Upload Error",
        message: `Error uploading ${file.name}`,
        type: "warning",
      });
      console.log("ERROR", error);
    }
  };

  const removeMedia = (name: string) => {
    removeFile(name);
    if (props.onMediaRemove) {
      props.onMediaRemove(name);
    }
  };

  const cleanedFiles = createMemo(() => {
    if (!props.line().Media) return [];
    const { files } = cleanUploads(props.line().Media);
    return files;
  });

  return (
    <div class="col-span-full">
      <p class="font-bold text-sm text-roma-dark-grey mb-2">
        Product Images / Video
        <span class="font-light text-xs ml-1">
          (Max 5 items, 50MB max)
        </span>{" "}
        <Show when={props.validationState === "invalid"}>
          <br />
          <span class="text-xs text-red-600 font-normal ">{props.error}</span>
        </Show>
      </p>
      <div
        ref={dropzoneRef}
        class="dropzone grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 items-center gap-4 p-4 min-h-[8.5rem] border-2 border-dashed border-gray-300  hover:border-neutral-400 rounded-md "
      >
        <button
          type="button"
          onClick={() => {
            selectFiles((files) => {
              if (files) addMedia(files);
            });
          }}
          class="w-full grow aspect-square border border-gray-300 hover:border-neutral-400 bg-roma-grey rounded-md flex flex-col items-center justify-center text-roma-medium-grey hover:text-roma-dark-grey text-xs"
        >
          <Icon path={photo} class="w-10" />
          <p>Add Image/Video</p>
        </button>
        <Show when={!props.line().Media || props.line()?.Media.length === 0}>
          <p class="text-sm text-roma-dark-grey sm:col-span-2 lg:col-span-5">
            Drag & drop images/videos or click 'Add Image / Video' to begin
          </p>
        </Show>

        <For each={cleanedFiles()}>
          {(file, index) => (
            <div class="relative w-full">
              <div
                class={` w-full aspect-square object-cover ${
                  props.line()?.Media[index()]?.status === "error"
                    ? "border-2 border-red-500"
                    : "border border-gray-300"
                } rounded-md overflow-hidden`}
              >
                <Switch>
                  <Match when={file.file.type.includes("image")}>
                    <img
                      src={file.source}
                      alt=""
                      class="object-cover w-full h-full"
                    />
                  </Match>
                  <Match when={file.file.type.includes("video")}>
                    <div class="bg-roma-grey w-full h-full flex flex-col items-center justify-center px-2 text-roma-dark-grey relative">
                      <Show when={props.line()?.Media[index()]?.thumbnailURL}>
                        <img
                          src={props.line().Media[index()].thumbnailURL}
                          alt=""
                          class="absolute inset-0 object-cover"
                        />
                      </Show>
                      <div class=" rounded-full aspect-square w-9 z-10 bg-white/80 flex justify-center items-center p-1">
                        <Icon path={videoCamera} class="w-6 z-10 " />
                      </div>
                      <p class="text-xs max-w-full line-clamp-1 z-10">
                        {file.name}
                      </p>
                    </div>
                  </Match>
                </Switch>
              </div>
              <button
                type="button"
                class="flex items-center justify-center w-7 h-7 aspect-square border-2 border-roma-medium-grey rounded-full bg-white text-roma-dark-grey hover:text-red-600 hover:border-red-600 absolute -right-2 -top-2 z-10 "
                disabled={props.line()?.Media[index()]?.status === "uploading"}
                onClick={() => {
                  removeMedia(file.name);
                }}
              >
                <Icon path={xMark} class="w-4" stroke-width={3} />
              </button>

              <Switch>
                <Match
                  when={props.line()?.Media[index()]?.status === "uploading"}
                >
                  <div class="absolute bottom-1.5 right-1.5 px-1.5 h-6 rounded-[4px] bg-white/80 flex items-center justify-center">
                    {/*  */}
                    <p class="text-roma-blue text-xs">
                      <Show
                        when={props.line()?.Media[index()]?.progress}
                        fallback={
                          <BaseLoader width={3} height={3} class="!mr-0" />
                        }
                      >
                        {(val) => <span>{val()}%</span>}
                      </Show>
                    </p>
                  </div>
                </Match>
                <Match
                  when={props.line()?.Media[index()]?.status === "converting"}
                >
                  <div class="absolute bottom-1.5 right-1.5 px-1.5 h-6 rounded-[4px] bg-white/80 flex items-center justify-center">
                    <BaseLoader width={3} height={3} class="!mr-0" />
                  </div>
                </Match>
                <Match
                  when={props.line()?.Media[index()]?.status === "success"}
                >
                  <div class="absolute bottom-1.5 right-1.5 w-6 aspect-square rounded-[4px] bg-white/80 flex items-center justify-center">
                    <Icon
                      path={check}
                      class="w-4 h-4 text-success-green"
                      stroke-width={3}
                    />
                  </div>
                </Match>
                <Match when={props.line()?.Media[index()]?.status === "error"}>
                  <div class="absolute bottom-1.5 inset-x-1.5  h-6 rounded-[4px] bg-white/80 flex items-center justify-between px-1.5">
                    <p class="text-red-500 text-xs font-bold">ERROR</p>
                    <Icon
                      path={xMark}
                      class="w-4 h-4 text-red-500"
                      stroke-width={3}
                    />
                  </div>{" "}
                </Match>
              </Switch>
            </div>
          )}
        </For>
      </div>
    </div>
  );
};
