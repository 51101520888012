import {
  Component,
  Show,
  Switch,
  Match,
  createMemo,
  useContext,
} from "solid-js";
import { SiteContext, useSiteContext } from "~/utils/contexts";
import { EventType } from "@solid-primitives/analytics";
import { Motion, Presence } from "solid-motionone";
import { SelectBox, TextFieldInput, Checkbox } from "~/components/inputs" 
import { ProductImage } from "~/components/product";
import { imageUrl } from "~/utils/products";


import { JOIN, CHOP, RAIL, LENGTH, PT } from "~/utils/products";
// import { productTypesDictionary } from "../ecommerce/ordering/comps/TypeSelector";
import { productTypesDictionary } from "../order-page";



import {
  reasons,
  deserializeSku,
  dimensionString,
  qtyToDisplay,
} from "./helpers";

import { UploadDropzone } from "./UploadDropzone";
import type { UploadedImage } from "./types";

export type InvoicedLine = {
  SKU: string;
  Type: PT;
  Title?: string;
  Quantity?: number;
  CalculatedQuantity?: number;
  UnitPrice: number;
  Amount: number;
  Level?: string;
  Length?: number;
  LengthFraction?: string;
  Width?: number;
  WidthFraction?: string;
  Notes?: string;
  Selected?: boolean;
  RequestType?: string;
  Reason?: string;
  ReturnQuantity?: string;
  Media?: UploadedImage[];
  Message?: string;
};

export const ProductReturnRow: Component<{
  index: number;
  line: () =>
    | InvoicedLine
    | Pick<
        InvoicedLine,
        | "SKU"
        | "Type"
        | "ReturnQuantity"
        | "RequestType"
        | "Reason"
        | "Media"
        | "Selected"
      >;
  formData: () => any;
  setter: (...args: any) => void;
  errors: () => any;
}> = (props) => {
  const { track } = useSiteContext();
  const reasonsList = reasons.map((item) => ({ value: item, label: item }));

  const qtyInvalidation = createMemo(() => {
    let result: string | undefined;
    const availQty = qtyToDisplay(props.line())?.num;
    const returnQty = props.formData()?.Items?.[props.index]?.ReturnQuantity;
    if (returnQty) {
      if (isNaN(+returnQty)) result = "Enter a valid number";

      if (returnQty && availQty) {
        switch (true) {
          case availQty == 0:
            result = "Unshipped items cannot be returned";
            break;
          case +returnQty > availQty:
            result = `Cannot exceed ${availQty}`;
            break;
          case +returnQty == 0:
            result = "Select a qty to return";
            break;
        }
      }
    }
    return props.errors().Items?.[props.index]?.ReturnQuantity || result;
  });

  const disableLine = () => qtyToDisplay(props.line())?.num === 0;
  const toggleChecked = () => {
    const checked = props.formData()?.Items?.[props.index]?.Selected;
    if (!disableLine()) {
      props.setter(`Items.${props.index}.Selected`, !checked);
      if (!checked) {
        track(EventType.Event, {
          category: "return_request",
          action: "line_selected",
        });
      }
    }
  };

  return (
    <div
      class="border-b last:border-b-0"
      data-line-qty-invalid={qtyInvalidation()}
    >
      <div
        class={`bg-white py-4 grid items-center grid-cols-[45px_1fr]  sm:grid-cols-[45px_max-content_2fr_1fr] sm:grid-rows-1 text-sm ${
          props.line().Selected
            ? "text-black"
            : `${disableLine() ? "text-gray-300" : "text-roma-medium-grey"}`
        }`}
      >
        <div
          class={`w-full h-full flex justify-center items-center  pl-2 max-sm:row-span-2 ${
            props.line().Selected
              ? "border border-r-0 bg-faint-blue rounded-l-md"
              : ""
          }`}
        >
          <Checkbox
            disabled={disableLine()}
            name={`Items.${props.index}.Selected`}
            onChange={(checked) => {
              props.setter(`Items.${props.index}.Selected`, checked);
            }}
            checked={props.formData()?.Items?.[props.index]?.Selected}
          />
        </div>
        {/* IMAGE */}
        <div
          class={`relative w-[4.25rem] p-1 h-full hidden sm:flex items-center justify-center shrink-0 ${
            props.line().Selected
              ? "border border-x-0 bg-faint-blue"
              : "bg-roma-grey"
          } ${disableLine() ? "cursor-not-allowed" : "cursor-pointer"} `}
          onClick={toggleChecked}
        >
          <Show
            when={props.line().Type !== "product"}
            fallback={
              <div class="flex h-full items-center justify-center">
                <div class="w-10 aspect-square border-3 border-gray-200 flex items-center justify-center">
                  <div class="w-[65%] aspect-square border-3 ml-px border-gray-200" />
                </div>
              </div>
            }
          >
            <ProductImage
              src={imageUrl(
                deserializeSku(props.line().SKU),
                props.line().Type,
                1
              )}
              quality={90}
              width={48}
              height={48}
            />
          </Show>
          <Show when={disableLine()}>
            <div class="absolute inset-0 backdrop-blur-[2px] w-full h-full" />
          </Show>
        </div>
        {/* DETAILS */}
        <div
          class={`w-full sm:h-full px-2 max-sm:pt-2 sm:pl-2 sm:flex sm:items-center ${
            props.line().Selected
              ? "bg-faint-blue border max-sm:border-b-0 max-sm:border-l-0 max-sm:rounded-tr-md sm:border-x-0 py-3"
              : "py-1"
          } ${disableLine() ? "cursor-not-allowed" : "cursor-pointer"}`}
          onClick={toggleChecked}
        >
          <div>
            <p class="line-clamp-1">
              {props.line().SKU}
              {props.line().Type !== "product"
                ? " - " + productTypesDictionary[props.line().Type]
                : ""}
            </p>
            <p class="line-clamp-1">{(props.line() as InvoicedLine).Title}</p>
            <Switch>
              <Match when={[PT.JOIN, PT.CHOP, PT.RAIL].includes(props.line().Type)}>
                <p class="fraction line-clamp-1">
                  {dimensionString(props.line() as InvoicedLine)}
                </p>
              </Match>
            </Switch>
          </div>
        </div>
        {/* SECONDARY DETAILS */}
        <div
          class={`order max-sm:col-start-2 max-sm:px-2 max-sm:pb-2  sm:pl-1 ${
            props.line().Selected
              ? "bg-faint-blue border max-sm:border-t-0 border-l-0 max-sm:rounded-tr-none rounded-r-md py-3"
              : "py-1"
          } ${disableLine() ? "cursor-not-allowed" : "cursor-pointer"}`}
          onClick={toggleChecked}
        >
          <p>
            Qty: {qtyToDisplay(props.line())?.string}
            {props.line()?.Type === PT.LENGTH ? "'" : ""}
          </p>
          <p>
            Unit Price: ${(props.line() as InvoicedLine).UnitPrice.toFixed(2)}
          </p>
          <p>
            Total Price: ${(props.line() as InvoicedLine).Amount.toFixed(2)}
          </p>
        </div>
      </div>
      {/* RETURN OPTIONS */}
      <Presence exitBeforeEnter>
        <Show when={props.line().Selected}>
          <Motion.div
            initial={{
              opacity: 0,
              height: "0px",
              padding: "0px",
              marginBottom: "0px",
            }}
            animate={{
              opacity: 1,
              height: "auto",
              padding: "1rem",
              marginBottom: "1rem",
            }}
            exit={{
              opacity: 0,
              height: "0px",
              padding: "0px",
              marginBottom: "0px",
              transition: { duration: 0.1 },
            }}
            class="border border-gray-300 rounded-md grid sm:grid-cols-[2fr_1fr_3fr] child:w-full gap-y-2 sm:gap-x-2"
          >
            <SelectBox
              label="Request Type"
              placeholder="Select a request type"
              options={[
                { label: "Replacement", value: "replacement" },
                { label: "Return", value: "return" },
              ]}
              triggerClass={`bg-white ${
                props.errors().Items?.[props.index]?.RequestType
                  ? "border-red-300"
                  : ""
              }`}
              contentClass="max-w-[90vw]"
              valueClass="line-clamp-1"
              onChange={(option) =>
                props.setter(
                  `Items.${props.index}.RequestType`,
                  option.value as string
                )
              }
              error={props.errors().Items?.[props.index]?.RequestType}
              validationState={
                props.errors().Items?.[props.index]?.RequestType
                  ? "invalid"
                  : "valid"
              }
            />

            <TextFieldInput
              name={`Items.${props.index}.ReturnQuantity`}
              label={
                props.line()?.Type === LENGTH ? "Return Footage" : "Quantity"
              }
              placeholder={
                props.line()?.Type === LENGTH ? "Enter feet" : "Enter Qty"
              }
              labelClass="text-roma-dark-grey"
              onChange={(val) => {
                props.setter(`Items.${props.index}.ReturnQuantity`, val);
              }}
              error={qtyInvalidation()}
              validationState={qtyInvalidation() ? "invalid" : "valid"}
              required
            />

            <SelectBox
              label="Why are you returning?"
              placeholder="Select a reason"
              options={reasonsList}
              class="w-full "
              triggerClass={`bg-white ${
                props.errors().Items?.[props.index]?.Reason
                  ? "border-red-300"
                  : ""
              }`}
              contentClass="max-w-[90vw]"
              valueClass="line-clamp-1"
              onChange={(option) => {
                props.setter(
                  `Items.${props.index}.Reason`,
                  option.value as string
                );
                track(EventType.Event, {
                  category: "return_request",
                  action: "reason_selected",
                  value: option.value as string,
                });
              }}
              error={props.errors().Items?.[props.index]?.Reason}
              validationState={
                props.errors().Items?.[props.index]?.Reason
                  ? "invalid"
                  : "valid"
              }
            />

            <Show when={props.line().Reason === "Product no longer needed"}>
              <div class="col-span-full border border-gray-300 rounded-md bg-orange-50 text-sm p-4 text-roma-dark-grey">
                Note: Length orders only - 20% restocking fee and shipping
                applies.
              </div>
            </Show>

            {/* IMAGE UPLOAD */}

            <UploadDropzone
              itemIndex={props.index}
              storeSetter={props.setter}
              line={props.line}
              formData={props.formData}
              validationState={
                props.errors().Items?.[props.index]?.Media ? "invalid" : "valid"
              }
              error={props.errors().Items?.[props.index]?.Media}
              onMediaAdd={(files) => {
                props.setter((prev: { Items: InvoicedLine[] }) => {
                  const items = [...prev.Items];
                  const itemToModify = { ...items[props.index] };

                  if (!itemToModify.Media) itemToModify.Media = [];

                  itemToModify.Media = [...itemToModify.Media, ...files];
                  items[props.index] = itemToModify;

                  return { ...prev, Items: items };
                });
              }}
              onMediaRemove={(name) => {
                props.setter((prev: { Items: InvoicedLine[] }) => {
                  const items = [...prev.Items];
                  const itemToModify = { ...items[props.index] };

                  if (!itemToModify.Media) itemToModify.Media = [];

                  itemToModify.Media = [...itemToModify.Media].filter(
                    (file) => file.name !== name
                  );
                  items[props.index] = itemToModify;

                  return { ...prev, Items: items };
                });
              }}
            />

            <TextFieldInput
              name={`Items.${props.index}.Message`}
              label="Comments"
              type="textarea"
              rootClass="col-span-full"
              maxLength={200}
              autoResize
            />
          </Motion.div>
        </Show>
      </Presence>
    </div>
  );
};
